import React from "react";
import "./Message.css";

function Message(props)
{
    return(
        <div className="message-container">
            <h1>{props.m}</h1>
        </div>
    )
}

export default Message;
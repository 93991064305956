import './App.css';
import Message from './Components/Message';
import card from './Components/RachelCard.pdf';

function App() {
  return (
    <div className="App">
      <head>
        <title>F U</title>
      </head>
      <Message m="F U Rachel..." />
      <Message m="Jk..." />
      <Message m="I Appreciate You!" />
      <footer>
        <a href={card} download><p>Download Birthday Card</p></a>
      </footer>
    </div>
  );
}

export default App;
